<template>
    <div>
        <title-bar
            :submitBtn="canCreate"
            :submitBtnTitle="$t('messages.orderSelectedItems')"
            :title="$t('navigations.itemsToOrder')"
            @submitPressed="onSubmit"
        />
        <div v-if="rows" class="custom-table">
            <advanced-filter
                :fields-array="filterFields"
                :action="showFilterModal"
                @clearAction="showFilterModal = false"
                @paramsChange="setAdvancedParams"
            />
            <table-actions
                :actions="tableActions"
                :option="filterOption"
                :options="filterOptions"
                @onSearchInput="inputChange"
                @perPageChange="perPageChange"
                @filterChange="showFilterModal = true"
                :defaultPerPage="perPage"
            />
            <table-lite
                :is-slot-mode="true"
                :is-check-all="true"
                :has-checkbox="true"
                :is-loading="isLoading"
                :columns="columns"
                :rows="rows"
                :sortable="sortable"
                @do-search="setSort"
                @is-finished="isLoading = false"
                @return-checked-rows="updateCheckedRows"
            >
                <template v-slot:itemCategory="data">
                    {{
                        $helper.getEnumTranslation("ITEM_CATEGORY", data.value.itemCategory, $i18n.locale)
                    }}
                </template>
                <template v-slot:insertedBy="data">
                    {{
                        data.value.insertedBy.givenName + " " + data.value.insertedBy.familyName
                    }}
                </template>
                <template v-slot:reqQuantity="data">
                  {{ data.value.reqQuantity }}
                </template>
                <template v-slot:quantityToOrder="data">
                  <b-form-input
                      type="number"
                      :min="data.value.step"
                      :step="data.value.step"
                      v-model="data.value.quantityToOrder"
                  />
                </template>
                <template v-slot:leadTime="data">
                  <b-form-input
                      v-model="data.value.leadTime"
                  />
                </template>
                <template v-slot:price="data">
                  <b-form-input
                      v-model="data.value.price"
                  />
                </template>
                <template v-slot:vendor="data">
                  <v-select
                      v-model="data.value.vendorSelect"
                      :options="data.value.vendorsOptions"
                      v-on:close="updateItemVendorData(data.value.id)"
                  />
                </template>
                <template v-slot:orderedQuantity="data">
                  <div v-if="data.value.orderedQuantity">
                    {{ data.value.orderedQuantity.stockedQuantity }}/{{ data.value.orderedQuantity.quantity }}
                  </div>
                </template>
                <template v-slot:actions="data">
                    <div class="just-buttons">
                      <edit-btn :path="$helper.getEditUrl(getItemEditUrl(data.value), data.value.id)" />
                      <custom-btn v-if="data.value.itemToOrder" :icon="data.value.itemToOrder ? 'cart-check' : 'cart'" @click="setItemToOrder(data.value)"/>
                    </div>
                </template>
            </table-lite>
            <table-pagination
                v-model="currentPage"
                :total="pages"
                :per-page="perPage"
                @input="onChangePage"
            />
        </div>

    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import vSelect from "vue-select";
import Toast from "@/components/Toast.vue";
import {useToast} from "vue-toastification";
import CustomBtn from "@/components/Buttons/CustomBtn.vue";
import AdvancedFilter from "@/components/Table/AdvancedFilter.vue";

export default {
    name: "ItemsToOrder",
    components: {
      CustomBtn,
        TableLite,
        TablePagination,
        TableActions,
        EditBtn,
        TitleBar,
        vSelect,
        AdvancedFilter
    },
    mixins: [ListUtils, ResourceUtils],
    created() {
      this.setPermissions()
      this.load()
    },
    methods: {
        load() {
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage,
            };
            params["order[" + this.sortable.order + "]"] = this.sortable.sort;
            if (this.search && this.search.length) {
                params.search = this.search;
            }
            let filterVendorId = null
            this.advancedParams.forEach(element => {
              if (element.value !== '' && element.value !== null) {
                params[element.name] = element.value
                if (element.name === 'vendors') {
                  filterVendorId = parseInt(element.value.split('/').pop());
                }
              }
            })
            this.$Items.getResourceByUrl({ url: '/items/to_order', params }, 'item_list').then((response) => {
                this.pages = response.data["hydra:totalItems"];
                this.rows = response.data["hydra:member"];

                const params = {
                  pagination: false,
                  status: ['SENT', 'WAITING_FOR_INVOICE', 'INVOICE_PAID'],
                };

                this.rows.forEach((item) => {
                    if (item.vendors.length > 0) {

                      let index = 0

                      if (filterVendorId) {
                        index = item.vendors.map(e => e.vendor.id).indexOf(filterVendorId);
                      }

                      if (typeof index === 'undefined') {
                        index = 0
                      }

                      item.vendorSelect = {
                        label: item.vendors[index].vendor.name,
                        id: item.vendors[index].vendor['@id'],
                        numberId: item.vendors[index].vendor.id
                      }

                      item.vendorsOptions = []
                      item.vendors.forEach((itemVendor) => {
                        item.vendorsOptions.push({
                          label: itemVendor.vendor ? itemVendor.vendor.name: null,
                          id: itemVendor.vendor ? itemVendor.vendor['@id'] : null,
                          numberId: itemVendor.vendor ? itemVendor.vendor.id : null
                        })

                        if (itemVendor.vendor && itemVendor.vendor['@id'] === item.vendorSelect.id) {
                          item.price = itemVendor.price
                          item.leadTime = itemVendor.leadTime
                          item.reqQuantity = itemVendor.reqQuantity
                          item.quantityToOrder = itemVendor.reqQuantity
                          item.step = itemVendor.reqQuantity
                        }
                      })
                    }
                })

              this.$Orders.getCollection({ params }, 'order_list').then((response) => {
                let orders = response.data["hydra:member"];

                orders.forEach((order) => {
                  order.items.forEach((orderItem) => {
                    let index = this.orderItemsQuantities.findIndex(row => parseInt(row.itemId) === parseInt(orderItem.item.id));
                    if (index > 0) {
                      this.orderItemsQuantities[index].quantity = parseInt(this.orderItemsQuantities[index].quantity) + parseInt(orderItem.quantity)
                      this.orderItemsQuantities[index].stockedQuantity = parseInt(this.orderItemsQuantities[index].stockedQuantity) + parseInt(orderItem.stockedQuantity)
                    } else {
                      this.orderItemsQuantities.push({
                        itemId: orderItem.item.id,
                        quantity: parseInt(orderItem.quantity),
                        stockedQuantity: parseInt(orderItem.stockedQuantity),
                      })
                    }
                  });
                });

                this.rows.forEach((item) => {
                  if (typeof this.findOrderItemQuantities(item) !== 'undefined') {
                    item.orderedQuantity = this.findOrderItemQuantities(item);
                  }
                });

              });
            });
        },
      setAdvancedParams(data) {
        this.advancedParams = data
        this.load()
      },
      setPermissions() {
        this.canCreate = this.$helper.userCapability('CREATE', 'ORDERS')
      },
      setItemToOrder(item) {
        item.itemToOrder = !item.itemToOrder
        const body = {
          itemToOrder: item.itemToOrder
        }
        this.update(this.$Items, item.id, body, this.$t('messages.itemUpdated'), null, this.load);
      },
        updateItemVendorData(itemId) {
          this.rows.forEach((item) => {
            if (item.id === itemId) {
              item.vendors.forEach((itemVendor) => {
                if (item.vendorSelect) {
                  if (itemVendor.vendor && itemVendor.vendor['@id'] === item.vendorSelect.id) {
                    item.price = itemVendor.price
                    item.leadTime = itemVendor.leadTime
                    item.reqQuantity = itemVendor.reqQuantity
                    item.quantityToOrder = itemVendor.reqQuantity
                    item.step = itemVendor.reqQuantity
                  }
                } else {
                  item.price = null
                  item.leadTime = null
                  item.reqQuantity = null
                  item.quantityToOrder = null
                  item.step = 1
                }
              })
            }
          })
        },
        updateCheckedRows(data) {
          this.checkedRows = [];
          data.forEach((value) => {
            this.checkedRows.push({
              itemId: value,
              item: this.rows.find(row => parseInt(row.id) === parseInt(value)),
              index: this.rows.findIndex(row => parseInt(row.id) === parseInt(value)),
            })
          })
        },
        onSubmit() {
          const body = {
            items: []
          }

          try {
            this.checkedRows.forEach((item) => {
              body.items.push({
                item: item.item['@id'],
                quantity: this.rows[item.index].quantityToOrder,
                price: this.rows[item.index].price,
                leadTime: this.rows[item.index].leadTime,
                vendor: this.rows[item.index].vendorSelect.id
              })
            })
          } catch(e) {
            this.$helper.showToast(useToast(), Toast, this.$t('forms.unexpectedError'), e.message, "danger");
            return
          }

          if(!body.items.length) {
            this.$helper.showToast(useToast(), Toast, this.$t('forms.noItemsSelected'), null, "danger");
            return
          }

          this.$Orders.createResourceByUrl({ url: '/orders/create_from_items', body}).then((response) => {
            const orders = response.data["hydra:member"];
            if (orders.length > 0) {
              this.redirect()
            }
          });
        },
        redirect() {
          this.$router.push(`/orders`);
        },
        getItemEditUrl(item) {
          let slug = item.itemCategory.toLowerCase().replaceAll('_','-')

          if (item.itemCategory !== 'IT') {
            slug += 's'
          }

          return slug
        },
        setSort(offset, limit, order, sort) {
          this.sortable.sort = sort;
          this.sortable.order = order;

          this.load();
        },
        onChangePage(page) {
          this.currentPage = page;
          this.load();
        },
        perPageChange(num) {
          this.currentPage = 1;
          this.perPage = num;
          this.load();
        },
        findOrderItemQuantities(item) {
          return this.orderItemsQuantities.find(row => parseInt(row.itemId) === parseInt(item.id));
        },
    },
    data() {
        return {
            search: "",
            isLoading: false,
            perPage: this.$store.state.defaultPerPage,
            currentPage: 1,
            pages: 0,
            showFilterModal: false,
            sortable: {
                order: "id",
                sort: "asc",
            },
            canCreate: false,
            filterFields: [
              {
                label: "forms.vendors",
                name: "vendors",
                value: null,
                type: "customSelect",
                resource: this.$Vendors,
                resourceType: "entity",
              },
            ],
            checkedRows: [],
            advancedParams: [],
            tableActions: ['perPage','advancedFilter'],
            columns: [
                {
                    label: this.$t("forms.id"),
                    field: "id",
                    sortable: true,
                    isKey: true,
                },
                {
                    label: this.$t("forms.number"),
                    field: "number",
                    sortable: false,
                },
                {
                    label: this.$t("forms.crossReference"),
                    field: "crossReference",
                    sortable: false,
                },
                {
                    label: this.$t("forms.description"),
                    field: "description",
                    sortable: false,
                },
                {
                    label: this.$t("forms.stock"),
                    field: "stock",
                    sortable: false,
                },
                {
                    label: this.$t("forms.minQuantity"),
                    field: "minQuantity",
                    sortable: false,
                },
                {
                  label: this.$t("forms.orderedQuantity"),
                  field: "orderedQuantity",
                  sortable: false,
                },
                {
                    label: this.$t("forms.text"),
                    field: "leadTime",
                    sortable: false,
                },
                {
                    label: this.$t("forms.quantityToOrder"),
                    field: "quantityToOrder",
                    sortable: false,
                    width: "150px"
                },
                {
                    label: this.$t("forms.price"),
                    field: "price",
                    sortable: false,
                    width: "150px"
                },
                {
                    label: this.$t("forms.vendor"),
                    field: "vendor",
                    sortable: false,
                    width: "200px"
                },
                {
                    label: this.$t("forms.actions"),
                    field: "actions",
                    sortable: false,
                    width: "80px",
                },
            ],
            rows: null,
            orderItemsQuantities: []
        };
    },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
